import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useQueryString } from "@capitaria/capitaria-utms"

import Tabs from "./tabs"
import Row from "./row"
import { definitionColumns } from "./columns"
import ShowMore from "../ui/images/show-more"
import { trackEvent } from "../../helpers/tracker"
import { transformNameCategory } from "../../helpers/transform-name-category"
import { getInstruments } from "../../helpers/instruments"

const Container = styled.div`
  max-width: 1120px;
  width: 100%;
`

const Columns = styled.div`
  align-items: center;
  background-color: #e7ebf0;
  display: flex;
  font-size: 12px;
  height: 64px;
  justify-content: space-around;
  max-width: 1120px;
  text-align: center;
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`

const SortIndicator = styled.span`
  position: relative;
  margin-left: 3px;

  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #818181;
    clear: both;
  }
  &::after {
    content: "";
    position: absolute;
    top: 2px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #818181;
    clear: both;
  }
`

const SortAsc = styled(SortIndicator)`
  &::after {
    border-top: 6px solid #000000;
  }
`

const SortDesc = styled(SortIndicator)`
  &::before {
    border-bottom: 6px solid #000000;
  }
`

const TabsContainerCSS = css`
  display: flex;
  justify-content: flex-start;
  background-color: #000000;
  > div {
    min-width: 100px;
    max-width: 120px;
  }
  @media (max-width: 768px) {
    display: flex;
    overflow: scroll;
  }
`

const TabCSS = css`
  background: #000000;
  font-weight: initial;
  color: rgba(245, 245, 245, 0.7);
  cursor: pointer;
  transition: none;
  padding-top: 22px;
  padding-bottom: 21px;

  &:hover {
    color: #ffffff;
    transition: none;
  }
`

const SelectedTabCSS = css`
  border-bottom: 5px solid #30ab76;
  color: #ffffff;
`

const ContainerShowMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #72c552;
  margin-top: 30px;
  padding: 10px;
  cursor: pointer;
  > div {
    margin-right: 5px;
  }
`

type TableProps = {
  data: ListCategory
  typeAccount: "start" | "pro" | "premium"
  search: any
  selectedMarketValue: string
  country: string
}

const InstrumentsTable = ({
  data,
  typeAccount,
  search,
  selectedMarketValue,
  country,
}: TableProps) => {
  const mcOrigen = useQueryString().parsedQueryString.mc_origen
  const numberPerPage = 10
  const [numberElements, setNumberElements] = useState(numberPerPage)
  const [selectedCategory, setSelectedCategory] = useState("all")
  const [sort, setSort] = useState({ column: "nemo", desc: false })

  useEffect(() => {
    setSelectedCategory("all")
    setNumberElements(numberPerPage)
  }, [typeAccount, selectedMarketValue])

  let categories = [
    {
      value: "all",
      name: "Todos",
    },
  ]

  const listCategories = Object.keys(data.categories)

  if (listCategories.length > 1) {
    categories = [
      ...categories,
      ...listCategories.map(category => ({
        value: category,
        name: transformNameCategory(category),
      })),
    ]
  }

  const onSelectCategory = (selectedTab: any) => {
    if (selectedCategory !== selectedTab.value) {
      setNumberElements(numberPerPage)
      setSelectedCategory(selectedTab.value)
      trackEvent(`aspectos-operativos:market:category`, {
        mc_origen: mcOrigen,
        market: selectedMarketValue,
        category: selectedTab.value,
        typeAccount,
      })
    }
  }

  let instruments: Instrument[] = getInstruments(
    data.categories,
    selectedCategory,
    search,
    sort
  )

  const onSort = (column: string) => () => {
    if (column === sort.column) {
      setSort({
        ...sort,
        desc: !sort.desc,
      })
    } else {
      setSort({
        column,
        desc: true,
      })
    }
  }

  const showMoreInstruments = () => {
    setNumberElements(numberElements + numberPerPage)
    trackEvent(`aspectos-operativos:show-more`, {
      mc_origen: mcOrigen,
      market: selectedMarketValue,
      category: selectedCategory,
      typeAccount,
    })
  }

  return (
    <Container>
      {search === null && (
        <Tabs
          tabs={categories}
          selectedValue={selectedCategory}
          search={search}
          onSelected={onSelectCategory}
          styleCss={TabsContainerCSS}
          styleTab={TabCSS}
          styleSelectedTab={SelectedTabCSS}
        />
      )}
      <Columns>
        {definitionColumns(typeAccount).map((column: any, idx: number) => (
          <column.component
            key={`idcolumn-${idx}`}
            onClick={column.sort ? onSort(column.sort) : () => {}}
          >
            <span>{column.text}</span>
            {column.sort === sort.column &&
              (sort.desc ? <SortAsc /> : <SortDesc />)}
          </column.component>
        ))}
      </Columns>
      {instruments
        .slice(0, numberElements)
        .map((instrument: Instrument, idx: number) => (
          <Row
            key={`idrow-${idx}`}
            instrument={instrument}
            typeAccount={typeAccount}
            country={country}
          />
        ))}

      {numberElements < instruments.length && (
        <ContainerShowMore onClick={showMoreInstruments}>
          <div>Ver más</div>
          <ShowMore />
        </ContainerShowMore>
      )}
    </Container>
  )
}

export default InstrumentsTable
