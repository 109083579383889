import moment from "moment-timezone"

const nextChangeHoursChile = new Date("2020-09-06 00:00:00") > new Date()

type TimezoneCountries = {
  [key: string]: string
}

const timezoneCountries: TimezoneCountries = {
  chile: "America/Santiago",
  peru: "America/Lima",
  uruguay: "America/Montevideo",
  mexico: "America/Mexico_city",
}

const normalizeHoursWithTimezone = (country: string) => (
  accumulator: string,
  hourSplitted: string
) => {
  const hourFormat = moment
    .tz(`${hourSplitted.trim()}`, "HH:mm", timezoneCountries["chile"])
    .tz(timezoneCountries[country])
    .format("HH:mm")
  if (accumulator === "") return hourFormat
  return `${accumulator} - ${hourFormat}`
}

const renderBreaklineFromSchedule = (country: string, joiner: JSX.Element) => (
  accumulatorHours: (string | JSX.Element)[],
  element: string
) => {
  const hour = element
    .split("-")
    .reduce(normalizeHoursWithTimezone(country), "")
  if (!accumulatorHours.length) {
    return [hour]
  }
  return [...accumulatorHours, joiner, hour]
}

export const changesHours = (
  hours: string,
  splitter: string,
  joiner: JSX.Element,
  country: string
) =>
  hours.split(splitter).reduce(renderBreaklineFromSchedule(country, joiner), [])
