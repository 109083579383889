import React from "react"

import Layout from "../components/ui/layout"
import SEO from "../components/seo"
import OperationalAspects from "../components/operational-aspects-page/operational-aspects-page"
import { QueryStringProvider } from "@capitaria/capitaria-utms"

const AspectosOperativos = () => (
  <QueryStringProvider>
    <Layout>
      <SEO title="Mercados y Aspectos Operativos" />
      <OperationalAspects />
    </Layout>
  </QueryStringProvider>
)

export default AspectosOperativos
