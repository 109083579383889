import React, { useState } from "react"
import styled from "@emotion/styled"

const Container = styled.div`
  margin-bottom: 40px;
`

const BarConsider = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  cursor: pointer;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
`

const Button = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  span {
    position: absolute;
    transition: 0.3s;
    background: #72c552;
    &:first-of-type {
      top: 25%;
      bottom: 25%;
      width: 10%;
      left: 45%;
    }
    &:last-of-type {
      left: 25%;
      right: 25%;
      height: 10%;
      top: 45%;
    }
  }
  &.open {
    span {
      &:first-of-type,
      &:last-of-type {
        transform: rotate(90deg);
      }
      &:last-of-type {
        left: 50%;
        right: 50%;
      }
    }
  }
`

const ContainerParagraph = styled.div`
  padding: 0 20px;
  font-size: 14px;
  line-height: 19px;
`

const ParagraphText = styled.div`
  margin: 20px 0;
`

type Props = {
  typeAccount: string
}

const Consider = ({ typeAccount }: Props) => {
  const [open, setOpen] = useState(false)
  return (
    <Container>
      <BarConsider onClick={() => setOpen(!open)}>
        <Title>* A TENER EN CUENTA:</Title>
        <Button className={open ? "open" : ""}>
          <span></span>
          <span></span>
        </Button>
      </BarConsider>
      {open && (
        <ContainerParagraph>
          {typeAccount === "start" && (
            <ParagraphText>
              <strong>Cuentas x0,x2 y x4:</strong> En la cuenta Start existen
              duplicados de los instrumentos diferenciados por un x0,x2 y x4.
              Esta es la forma en que puedes elegir el apalancamiento de dicho
              activo. De esta manera si decides operar el x0, significa que no
              tiene apalancamiento, x2 posee un apalancamiento de 2:1 y x4
              apalancamiento 4:1.
            </ParagraphText>
          )}
          <ParagraphText>
            <strong>Lote:</strong> Un lote es un paquete mínimo de transacción.
            Por ejemplo en el caso de las divisas, el tamaño estandar de un lote
            es de 100.000 unidades de la divisa base. Sin embargo, se puede
            operar con fracciones de un lote.
          </ParagraphText>
          <ParagraphText>
            <strong>Pip:</strong> Es la variación mínima de un valor. Por lo
            general es el cuarto punto decimal en un par de divisas. Por ejemplo
            si el valor de <strong>GBP/USD = 1.5486</strong>, el número 6 viene
            a ser el PIP.
          </ParagraphText>
          <ParagraphText>
            <strong>Spread:</strong> El costo que se cobra en Capitaria es el
            spread en cada operación. El spread es la diferencia entre el precio
            de compra y el precio de venta de un activo. Al igual que cuando uno
            va a una casa de cambio de divisas, existe un diferencial del precio
            de compra con el de venta.
          </ParagraphText>
          <ParagraphText>
            <strong>Margen:</strong> Es la garantía solicitada para poder abrir
            una operación.
          </ParagraphText>
          <ParagraphText>
            <strong>Swap/Rollover:</strong> La prima por rollover o swap es un
            proceso de ajuste financiero de los CFD, que se ejecuta al dejar una
            operación abierta de un día para otro.
          </ParagraphText>
          <ParagraphText>
            <strong>Margin Call:</strong> Es una herramienta de advertencia para
            que el cliente deposite más garantía o cierre alguna de sus
            posiciones, ya que está muy cerca de caer en Stop Out. Esta se
            activa cuando el nivel de margen está en un 90%.
          </ParagraphText>
          <ParagraphText>
            <strong>Stop Out:</strong> Es una orden automática que efectúa la
            plataforma pudiendo cerrar de manera parcial o total las operaciones
            abiertas del cliente, a excepción de aquellas que no están
            operativas por horario. Esta orden se activa cuando el nivel de
            margen mostrado en la plataforma llega al nivel de 50%.
          </ParagraphText>
        </ContainerParagraph>
      )}
    </Container>
  )
}

export default Consider
