import React from "react"
import styled from "@emotion/styled"
import Consider from "./consider"

const ContainerExtraInformation = styled.div`
  max-width: 697px;
  margin-top: 60px;
  @media (max-width: 670px) {
    padding: 0 40px;
  }
  @media (max-width: 460px) {
    padding: 0 10px;
  }
`

const ContainerImportant = styled.div`
  font-size: 14px;
  line-height: 19px;
  color: #363f41;
  padding: 0 15px;
`

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #000000;
`

const InformationBox = styled.div`
  padding: 25px 44px;
  background-color: #e7f9ff;
  color: #111c37;
  border-radius: 5px;
  margin-top: 15px;
`

const ParagraphText = styled.div`
  margin: 20px 0;
`

const Link = styled.a`
  text-decoration: underline;
  color: #363f41;
`

type Props = {
  typeAccount: string
}

const ExtraInformation = ({ typeAccount }: Props) => (
  <ContainerExtraInformation>
    <Consider typeAccount={typeAccount} />
    <ContainerImportant>
      <Title>IMPORTANTE</Title>
      <InformationBox>
        <strong>CAPITARIA no cobra comisiones por transacción.</strong> El único
        costo para el cliente será el spread de mercado.
      </InformationBox>
      <ParagraphText>
        • Para los CFD de CAPITARIA que tienen como{" "}
        <strong>activo subyacente los futuros</strong> con vencimientos
        específicos,{" "}
        <strong>
          el día en que el contrato expira todas las posiciones que están
          abiertas son cerradas automáticamente.
        </strong>
      </ParagraphText>
      <ParagraphText>
        • Si el cliente quisiera extender su posición, debe cerrar la operación
        que está por vencer y volver a abrir una nueva en el instrumento con el
        nuevo vencimiento más lejano.
      </ParagraphText>
      <ParagraphText>
        • Las <strong>órdenes pendientes</strong> colocadas en plataforma podrán ser canceladas
         por Capitaria cuando se presenten ciertas situaciones que ameriten su anulación, como por ejemplo, 
         inactivación de la cuenta, documentación pendiente, entre otros. Cuando esto ocurra, en la 
         plataforma quedará registrada la cancelación de la orden pendiente, lo cual podrá ser visualizado 
         por el cliente en su cuenta.
      </ParagraphText>
      <ParagraphText>
        • <strong>Todos los spread son variables</strong> y se ajustan a las
        condiciones de mercado.
      </ParagraphText>
      <ParagraphText>
        • En el caso de los <strong>CFDs spot</strong> no existe fecha de
        expiración pero están
        <strong>
          {" "}afectos a un cargo o abono por{" "}
          <Link
            href="https://capitaria.zendesk.com/hc/es/articles/360044315091--Qu%C3%A9-es-el-Swap-o-Rollover-y-d%C3%B3nde-puedo-verlo-"
            target="_blank"
            rel="noopener noreferrer"
          >
            rollover
          </Link>.
        </strong>
        </ParagraphText>
      <ParagraphText>
        • Para compensar el fin de semana,
        <strong> el proceso de swap</strong> se aplica triple
        los días miércoles para Forex y CFD de commodities, y los viernes para
        CFD de acciones, CFD de índices bursátiles.
      </ParagraphText>
      <ParagraphText>
        • <strong>Todos estos productos están estructurados como CFD</strong>,
        es decir, al cierre existe un pago por compensación y no un traspaso
        físico del activo subyacente.
      </ParagraphText>
      <ParagraphText>
        • Cada instrumento está valorizado en la divisa de su propio mercado.
        Por ende, cuando se obtiene una ganancia o pérdida, se refleja en la
        moneda a la cual está afecta el instrumento, y se convierte a dólares o
        pesos chilenos al tipo de cambio que está mostrando la plataforma en ese
        instante.
      </ParagraphText>
      <ParagraphText>
        • Los rescates de los clientes serán realizados en un plazo máximo de 48
        horas bancarias
      </ParagraphText>
    </ContainerImportant>
  </ContainerExtraInformation>
)

export default ExtraInformation
